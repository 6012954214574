import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { Injectable } from '@angular/core'
import { NgbDatepickerI18n as NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  private weekdaysShort: string[] = []

  private months: string[] = []

  constructor (private translate: TranslateService) {
    super()
    this.loadTranslations()
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslations()
    })
  }

  public loadTranslations (): void {
    this.translate.get(`COMPONENTS.DATE_RANGE.DAYS`).subscribe((days: string[]) => {
      this.weekdaysShort = days
    })
    this.translate.get(`COMPONENTS.DATE_RANGE.MONTHS`).subscribe((months: string[]) => {
      this.months = months
    })
  }

  public getWeekdayLabel (weekday: number): string {
    return this.weekdaysShort[weekday - 1]
  }

  public getMonthShortName (month: number): string {
    return this.months[month - 1]
  }

  public getMonthFullName (month: number): string {
    return this.months[month - 1]
  }

  public getDayAriaLabel (date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`
  }

}
