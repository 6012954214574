import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http'
import { JwtInterceptor } from './shared/jwt.interceptor'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import {
  NgbDatepickerI18n,
  NgbDatepickerModule,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap'
import { SidebarComponent } from './shared/sidebar/sidebar.component'
import { NavbarComponent } from './shared/navbar/navbar.component'
import { ModalModule } from './shared/components/modal/modal.module'
import { ToastsModule } from './shared/components/toasts/toasts.module'
import { CustomDatepickerI18n } from './shared/services/CustomDatepickerI18n.service'

export function createTranslateLoader (http: HttpClient) {
  return new TranslateHttpLoader(http, `./assets/i18n/`, `.json`)
}

@NgModule({
  declarations: [AppComponent, SidebarComponent, NavbarComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: createTranslateLoader
      }
    }),
    ModalModule,
    NgbModule,
    ToastsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
